@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
}

.top-nav--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;

  /* adjust to resize */
  font-size: 1rem;
}

.top-nav--wrapper img {
  max-width: 18.25em;
  width: 100%;
  margin-left: 3.3125em;
}

.top-nav--options {
  display: flex;
  gap: 5.1875em;
  margin-right: 4.3125em;
  margin-top: 1.875em;
  margin-bottom: 1.625em;
}

.top-nav--wrapper a {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  text-transform: capitalize;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625em;
  line-height: 1.24em;

  color: var(--base-accent-color-light);
}

.top-nav--wrapper a:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--base-accent-color-light);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.top-nav--wrapper a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.top-nav--options button {
  font-family: "Catamaran";
  font-size: 1.5625em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.64em;
  padding: 0.48em 2.76em 0.44em 2.96em;
  border-radius: 0.4em;
  border: none;
  text-align: center;
  text-transform: capitalize;
  background-color: var(--base-accent-color-light);
  color: var(--base-white-color);
  cursor: pointer;
}

@media (min-width: 18.75em) {
  .top-nav--wrapper {
    font-size: 0.26rem;
  }
}

@media (min-width: 28.125em) {
  .top-nav--wrapper {
    font-size: 0.33rem;
  }
}

@media (min-width: 37.5em) {
  .top-nav--wrapper {
    font-size: 0.41rem;
  }
}

@media (min-width: 50em) {
  .top-nav--wrapper {
    font-size: 0.54rem;
  }
}

@media (min-width: 62.5em) {
  .top-nav--wrapper {
    font-size: 0.64rem;
  }
}

@media (min-width: 75em) {
  .top-nav--wrapper {
    font-size: 0.7rem;
  }
}

@media (min-width: 87.5em) {
  .top-nav--wrapper {
    font-size: 0.81rem;
  }
}

@media (min-width: 100em) {
  .top-nav--wrapper {
    font-size: 0.9rem;
  }
}

@media (min-width: 118.75em) {
  .top-nav--wrapper {
    font-size: 1rem;
  }
}

@media (min-width: 143.75em) {
  .top-nav--wrapper {
    font-size: 1.15rem;
  }
}

@media (min-width: 156.25em) {
  .top-nav--wrapper {
    font-size: 1.26rem;
  }
}
