.signup-page {
   height: 90vh;
   padding: 7vh 10%;
   width: 80%;
   background-color: #ffffff;
   display: flex;
}

.character {
   width: 80vh;
}

.signup {
   width: 55%;
   height: 95%;
   box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
   padding: 15px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.logo-signup {
   height: 5vh;
   margin-bottom: 7%;
}

.create-account-prompt {
   font-family: Arial, Helvetica, sans-serif;
   font-size: 20px;
   margin-bottom: 2%;
}

.signup-inputs {
   width: 55%;
   align-content: center;
   margin-bottom: 20px;
}

.signup-inputs input {
   width: 100%;
   height: 35px;
   border-radius: 4px;
   border: 1px solid grey;
   padding-left: 2.5%;
   font-size: 15px;
}

.signup-inputs label {
   margin-left: 2.5%;
   font-size: 15px;
   font-weight: 500;
   z-index: 1;
   background-color: white;
}

.signup-submit {
   background: #ff4438;
   height: 40px;
   border-radius: 10px;
   border-style: none;
   color: white;
   width: 57%;
   font-size: 15px;
   font-weight: 600;
   margin-bottom: 15px;
   cursor: pointer;
}

.signin-prompt {
   display: inline-block;
   align-self: center;
   width: 100%;
   height: 16px;
   text-align: center;
   font-size: 13.5px;
   font-weight: 400;
   font-family: Arial, Helvetica, sans-serif;
   font-style: normal;
   color: #455063;
   white-space: nowrap;
   margin-bottom: 10px;
}

#signup-link {
   text-decoration: none;
   color: #3880a7;
}

#or {
   display: inline-block;
   align-self: center;
   width: 100%;
   height: 16px;
   text-align: center;
   font-size: 13.5px;
   font-weight: 400;
   font-family: Arial, Helvetica, sans-serif;
   font-style: normal;
   color: #455063;
   white-space: nowrap;
   margin-bottom: 10px;
}

.logos {
   display: inline-block;
   width: 100%;
   height: 20px;
   text-align: center;
}

#googleLogo {
   height: 100%;
   margin-right: 5%;
   align-self: left;
}

#facebookLogo {
   height: 100%;
   margin-left: 5%;
}

.client__feed__content__feed__notification__wrapper {
   display: flex;
   flex-direction: column;
   padding: 1em;
   gap: 1em;

   /* adjust */
   font-size: 0.7rem;
}

.client__feed__content__feed__header__text {
   font-family: "Rubik";
   font-style: normal;
   text-align: center;
   font-weight: 400;
   font-size: 1.875em;
   line-height: 1.2em;
   color: #454343;
}

.client__feed__content__feed__notification__buttons__wrapper {
   display: flex;
   justify-content: center;
   align-items: start;
   gap: 1em;
   margin-top: 1em;
}

.client__feed__content__feed__notification__upload__button {
   border: none;
   outline: none;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 700;
   font-size: 1.25em;
   line-height: 1.2em;
   padding: 0.6em 1.8em;
   border-radius: 0.2em;
   color: var(--base-white-color);
   background-color: var(--base-accent-color-darker);
}

@media (min-width: 476px) {
   /* client feed content feed */
   .client__feed__content__feed__notification__wrapper {
      font-size: 0.75rem;
      padding: 2em;
   }
}

@media (min-width: 636.8px) {
   /* client feed content feed */
   .client__feed__content__feed__notification__wrapper {
      padding: 3em;
   }
}

@media (min-width: 960px) {
   /* client feed content feed */
   .client__feed__content__feed__notification__wrapper {
      font-size: 0.8rem;
   }
}

@media (min-width: 1680px) {
   /* client feed content feed */
   .client__feed__content__feed__notification__wrapper {
      font-size: 0.9rem;
   }
}
