.footer__wrapper {
  background-color: var(--base-accent-color-light);
  padding: 4em 6em;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  color: var(--base-white-color);

  /* adjust to resize */
  font-size: 1rem;
}

.footer__body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4em;
  margin-bottom: 2em;
}

/* summery */

.summery__logo {
  width: 40%;
  max-width: 11.625em;
  margin-bottom: 2.875em;
}

.summery__text {
  font-size: 1.125em;
  line-height: 1.6667em;
  margin-bottom: 1.3333em;
}

.summery__Links {
  display: flex;
  gap: 1em;
}

.summery__Links img {
    max-width: 1.1875em;
}

/* quick Links */

.quick__links__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quick__links__header {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.2222em;
  margin-bottom: 3.3em;
}

.quick__links__link__container {
  display: flex;
  flex-direction: column;
  gap: 0.625em;
}

.quick__links__link__container a {
  text-decoration: none;
  font-size: 1.125em;
  line-height: 1.6667em;
  color: var(--base-white-color);
}

/* contact details */

.contact__link__container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.contact__header {
  font-weight: 700;
  font-size: 1.125em;
  line-height: 1.2222em;
  margin-bottom: 3.3em;
}

.contact__link {
  display: flex;
  gap: 0.5em;
}

.contact__link:first-child {
  align-items: flex-start;
}

.contact__link__image {
  width: 100%;
  max-width: 1.25em;
}

.contact__link__text {
  font-size: 1.125em;
  line-height: 1.6667em;
}

.contact__link:nth-child(2) {
  text-decoration: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125em;
  line-height: 1.2222em;
  color: var(--base-white-color);
}

/* get in touch form */

.form__Wrapper {
  display: flex;
  flex-direction: column;
}

.form__header {
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.2222em;
  margin-bottom: 1.6667em;
}

.form__body {
  display: flex;
  flex-direction: column;
  gap: 1.875em;
}

.form__input,
.form__textarea {
  font-size: 1.25em;
  border-radius: 0.6em;
  outline: none;
  border: none;
  cursor: text;
  text-indent: 3%;
  width: 100%;
}

.form__input {
  padding: 0.74em 0;
}

.form__textarea {
  padding: 0.74em 0;
  resize: none;
}

.form__button {
  font-size: 1.25em;
  line-height: 1em;
  padding: 0.74em 0;
  border-radius: 0.6em;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #1c448e;
  color: var(--base-white-color);
}

/* footer lower section */

.copyright__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1.875em;
}

.copyright__text {
  font-size: 1.125em;
  line-height: 1.6667em;
}

.copyright__policies {
    display: flex;
}

.copyright__policies a {
  font-size: 1.125em;
  line-height: 1.6667em;
  color: var(--base-white-color);
}

.copyright__policies a:first-child {
    border-right: 1px solid var(--base-white-color);
    padding-right: 1em;
    margin-right: 1em;
}

@media (min-width: 18.75em) {
    .footer__wrapper {
      font-size: 0.26rem;
    }
  }
  
  @media (min-width: 28.125em) {
    .footer__wrapper {
      font-size: 0.33rem;
    }
  }
  
  @media (min-width: 37.5em) {
    .footer__wrapper {
      font-size: 0.41rem;
    }
  }
  
  @media (min-width: 50em) {
    .footer__wrapper {
      font-size: 0.54rem;
    }
  }
  
  @media (min-width: 62.5em) {
    .footer__wrapper {
      font-size: 0.64rem;
    }
  }
  
  @media (min-width: 75em) {
    .footer__wrapper {
      font-size: 0.7rem;
    }
  }
  
  @media (min-width: 87.5em) {
    .footer__wrapper {
      font-size: 0.81rem;
    }
  }
  
  @media (min-width: 100em) {
    .footer__wrapper {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 118.75em) {
    .footer__wrapper {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 143.75em) {
    .footer__wrapper {
      font-size: 1.15rem;
    }
  }
  
  @media (min-width: 156.25em) {
    .footer__wrapper {
      font-size: 1.26rem;
    }
  }