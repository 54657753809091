.clientAdmin__page__wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 97%;
   overflow-x: hidden;
}

.clientAdmin__black__screen {
   height: 100vh;
   width: 100vw;
   position: fixed;
   z-index: 3;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.7);
   overflow-y: auto;
   overflow-x: hidden;
   text-align: center;
   opacity: 0;
   transition: opacity 0.5s;
   pointer-events: none;
   z-index: 1;
}

.clientAdmin__black__screen--sidebar__open {
   opacity: 1;
   z-index: 5;
   pointer-events: auto;
}

.clientAdmin__page__content__wrapper {
   display: flex;
   flex-direction: column;
}

.clientAdmin__topBar__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1em 0 1.25em 0;
   width: 100%;
   font-size: 0.8rem;
}

.clientAdmin__logo__image__wrapper {
   width: 40vw;
}

.clientAdmin__logo__image {
   display: none;
   width: 100%;
   max-width: 12.9375em;
}

.clientAdmin__topBar__content {
   display: flex;
   align-items: center;
   gap: 4.9375em;
   margin-right: 1.125em;
}

.clientAdmin__topBar__profile__wrapper {
   display: flex;
   position: relative;
   border-radius: 2.5em;
   width: 11.125em;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 500;
   background: #d2d9e2;
   cursor: pointer;
}

.clientAdmin__topBar__profile__image {
   height: 2.8125em;
   width: 2.8125em;
   border-radius: 100%;
   border: 2.5px solid #ff443880;
   overflow: hidden;
   background-size: cover;
   background-position: center;
   background-image: url("../../../assets/default-profile-picture.svg");
}

.clientAdmin__topBar__profile__name {
   margin-top: 0.4em;
   margin-left: 0.4em;
   font-size: 0.9375em;
   line-height: 1.2em;
}

.clientAdmin__topBar__profile__image__Arrow {
   position: absolute;
   right: 8%;
   top: 43%;
   width: 7%;
   max-width: 0.725em;
}

.clientAdmin__head__span {
   background: var(--base-accent-color-light);
   border-radius: 1.5em 0px 1.5em 1.5em;
   padding: 2em;
   margin-bottom: 1em;
}

@media (min-width: 374px) {
}

@media (min-width: 476px) {
   .clientAdmin__topBar__wrapper {
      font-size: 0.9rem;
   }
}

@media (min-width: 636.8px) {
   .clientAdmin__head__span {
      padding: 3em;
   }
}

@media (min-width: 748.8px) {
}

@media (min-width: 768px) {
   .clientAdmin__head__span {
      padding: 3.5em;
   }
}

@media (min-width: 800px) {
}

@media (min-width: 872px) {
}

@media (min-width: 960px) {
   .clientAdmin__page__wrapper {
      margin-left: 37px;
   }

   .clientAdmin__topBar__wrapper {
      justify-content: space-between;
   }
   .clientAdmin__logo__image {
      display: block;
      margin-left: 0.3em;
   }
   /* .clientAdmin__page__content__wrapper {
      width: 90%;
   } */
}

@media (min-width: 1020px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1328px) {
}

@media (min-width: 1440px) {
}

@media (min-width: 1680px) {
   .clientAdmin__topBar__wrapper {
      font-size: 1rem;
   }
}

@media (min-width: 2080px) {
   .clientAdmin__topBar__wrapper {
      font-size: 1.2rem;
   }
}
