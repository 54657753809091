@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&family=Mulish&family=Poppins&family=Roboto:wght@500;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

:root {
   --base-accent-color-light: #4d74ff;
   --base-accent-color-dark: #1c448e;
   --base-accent-color-darker: #1c448d;
   --base-white-color: #ffffff;
   --base-gray-color: rgb(112, 112, 112);
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

html {
   overflow-y: scroll;
}

button {
   cursor: pointer;
}

::-webkit-scrollbar {
   width: 10px;
}

::-webkit-scrollbar-track {
   background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
   background: #888;
}

::-webkit-scrollbar-thumb:hover {
   background: #555;
}
