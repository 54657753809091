* {
   box-sizing: unset;
}

.client__page__wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   max-width: 97%;
   overflow-x: hidden;
}

.client__black__screen {
   height: 100vh;
   width: 100vw;
   position: fixed;
   z-index: 700;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.7);
   overflow-y: auto;
   overflow-x: hidden;
   text-align: center;
   opacity: 0;
   transition: opacity 0.5s;
   pointer-events: none;
   z-index: 1;
}

.client__black__screen--sidebar__open {
   opacity: 1;
   z-index: 5;
   pointer-events: auto;
}

.client__page__content__wrapper {
   display: flex;
   flex-direction: column;
}

.client__topBar__wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1em 0 1.25em 0;
   width: 100%;
   font-size: 0.8rem;
}

.client__logo__image {
   display: none;
   width: 100%;
   max-width: 12.9375em;
}

.client__topBar__content {
   display: flex;
   align-items: center;
   gap: 0.6em;
   width: 100%;

   /* adjust */
   justify-content: center;
   flex-direction: column-reverse;
}

.client__topBar__content__section {
   display: flex;
   gap: 0.5em;
   justify-content: center;
   align-items: center;
   width: max-content;
}

.client__topBar__profile__wrapper {
   display: flex;
   position: relative;
   border-radius: 2.5em;
   width: 11.125em;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 500;
   background: #d2d9e2;
   cursor: pointer;
}

.client__topBar__profile__image {
   height: 2.8125em;
   width: 2.8125em;
   border-radius: 100%;
   border: 2.5px solid #ff443880;
   overflow: hidden;
   background-size: cover;
   background-position: center;
   background-image: url("../../../assets/default-profile-picture.svg");
}

.client__topBar__profile__name {
   margin-top: 0.4em;
   margin-left: 0.4em;
   font-size: 0.9375em;
   line-height: 1.2em;
}

.client__topBar__profile__image__Arrow {
   position: absolute;
   right: 8%;
   top: 43%;
   width: 7%;
   max-width: 0.725em;
}

.client__head__span {
   background: var(--base-accent-color-light);
   border-radius: 1.5em 0px 1.5em 1.5em;
   padding: 2em;
   margin-bottom: 1em;
}

/*  */

.client__topBar__wrapper {
   position: relative;
   display: flex;
   align-items: center;
   padding: 1em 0 1.25em 0;
   width: 100%;

   /* adjust */
   font-size: 0.8rem;
   justify-content: flex-end;
}

.client__topBar__donate__wrapper {
   display: flex;
   align-items: center;
   flex-shrink: 0;
   gap: 0.5em;
}

.client__topBar__donate__text {
   font-family: "Rubik";
   font-style: normal;
   font-weight: 500;
   font-size: 0.9675em;
   line-height: 1.2em;
   color: darkred;
}

.client__topBar__donate__button {
   display: flex;
   flex-shrink: 0;
   flex-direction: column;
   align-items: center;
   border: none;
   justify-content: center;
   padding: 0.8em 1.6em;
   border-radius: 0.5333em;
   background-color: var(--base-accent-color-darker);
   color: var(--base-white-color);
   font-family: "Rubik";
   font-style: normal;
   font-weight: 500;
   font-size: 0.7675em;
   line-height: 1.2em;
   cursor: pointer;
}

.client__topBar__points__info__wrapper__size__controller {
   font-size: 0.6rem;
   cursor: pointer;
   position: relative;
}

.client__topBar__points__info__wrapper {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   place-items: center;
   max-width: 15.3333em;
   min-width: 13em;
   max-height: 4.1333em;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 500;
   line-height: 1.2em;
   border-radius: 0.5333em;
   background-color: var(--base-accent-color-light);
   color: var(--base-white-color);
   font-size: 0.9375em;
   /* transition: opacity 300ms; */

   /* adjust */
   grid-gap: 0.4em;
   padding: 0.8em 0.8em;

   /* animation adjust */
   opacity: 1;
}

.client__topBar__points__info__wrapper--opacity__none {
   opacity: 0;
   pointer-events: none;
}

.client__topBar__points__info__wrapper--dynamic {
   /* adjust */
   --left-value: 0%;
   --top-value: 0%;
   --final-top-value: 900%;
   --right-value: 0;
   --font-size: 0.9375em;

   --left-value-change: calc(var(--left-value) / 5);
   --top-value-change: calc((var(--final-top-value) - var(--top-value)) / 5);

   display: none;
   position: absolute;
   background-color: var(--base-accent-color-light);
   /* opacity: 0; */
   width: max-content;
   height: min-content;
   z-index: 99999;
   font-size: var(--font-size);
   left: var(--left-value);
   right: var(--right-value);
   top: var(--top-value);
   margin-left: auto;
   margin-right: auto;
}

.client__topBar__points__info__wrapper--dynamic__enabled {
   display: grid;
   animation: animate__reward__increase 1000ms linear forwards;
}

.client__topBar__points__info__wrapper--dynamic__initial {
   display: grid;
   animation: animate__reward__increase__initial 1000ms linear backwards;
}

@keyframes animate__reward__increase {
   0% {
      font-size: var(--font-size);
      left: var(--left-value);
      right: var(--right-value);
      top: var(--top-value);
      margin-left: auto;
      margin-right: auto;
   }

   100% {
      font-size: calc(var(--font-size) + 1em);
      top: var(--final-top-value);
      left: -130%;
      right: 0%;
      margin-left: auto;
      margin-right: auto;
   }
}

@keyframes animate__reward__increase__initial {
   0% {
      font-size: calc(var(--font-size) + 1em);
      top: var(--final-top-value);
      left: -130%;
      right: 0%;
      margin-left: auto;
      margin-right: auto;
   }
   100% {
      font-size: var(--font-size);
      left: var(--left-value);
      right: var(--right-value);
      top: var(--top-value);
      margin-left: auto;
      margin-right: auto;
   }
}

/* @keyframes animate__reward__increase__pause {
   50%,
   60.5%,
   70.5%,
   80.5%,
   90.5% {
      font-size: calc(var(--font-size) + 1em);
      top: var(--final-top-value);
      left: -130%;
      right: 0%;
      margin-left: auto;
      margin-right: auto;
   }

   100% {
      font-size: var(--font-size);
      left: var(--left-value);
      right: var(--right-value);
      top: var(--top-value);
      margin-left: auto;
      margin-right: auto;
   }
} */

.client__topBar__points__info__text__wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   width: max-content;
}

.client__topBar__points__image {
   max-width: 2em;
   max-height: 1.825em;
}

.client__topBar__profile__wrapper {
   border: none;
   display: flex;
   position: relative;
   border-radius: 2.5em;
   width: 11.125em;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 500;
   background: #f1f6fd;
   cursor: pointer;
}

.client__topBar__profile__name {
   margin-top: 0.4em;
   margin-left: 0.4em;
   font-size: 0.9375em;
   line-height: 1.2em;
}

.client__topBar__profile__image {
   height: 2.8125em;
   width: 2.8125em;
   border-radius: 100%;
   border: 2.5px solid #ff443880;
   overflow: hidden;
   background-size: cover;
   background-position: center;
}

.client__topBar__profile__image__Arrow {
   position: absolute;
   right: 8%;
   top: 43%;
   width: 7%;
   max-width: 0.725em;
}

/* dropdown */

.client__topBar__dropdown {
   position: relative;
}

.client__topBar__dropdown__menu {
   position: absolute;
   left: 0;
   top: calc(100% + 0.25em);
   background-color: var(--base-white-color);
   padding: 0.75em;
   border-radius: 0.25em;
   box-shadow: 0 0.125em 0.3125em 0 rgba(0, 0, 0, 0.5);
   opacity: 0;
   pointer-events: auto;
   transform: translateY(-10px);
   transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
   z-index: 20;
}

.client__topBar__dropdown
   > .client__topBar__profile__wrapper:focus
   + .client__topBar__dropdown__menu {
   pointer-events: auto;
   opacity: 1;
   transform: translateY(0);
}

.client__topBar__dropdown__menu__item__wrapper {
   display: flex;
   flex-direction: column;
   gap: 0.5em;
   list-style: none;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 20px;
   color: #1b1a1a;
}

.client__topBar__dropdown__menu__item {
   cursor: pointer;
}

.client__topBar__dropdown__menu__item:hover {
   background-color: #c8d4ff;
}

@media (min-width: 476px) {
   .client__topBar__wrapper {
      font-size: 0.9rem;
   }
   .client__topBar__wrapper {
      font-size: 0.9rem;
      gap: 1.5em;
   }

   .client__topBar__points__info__wrapper__size__controller {
      font-size: 0.7rem;
   }

   .client__topBar__content {
      gap: 1em;
   }

   .client__topBar__points__info__wrapper {
      grid-gap: 0.6em;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 58.5%;
   } */
}

@media (min-width: 636.8px) {
   .client__head__span {
      padding: 3em;
   }

   .client__topBar__points__info__wrapper {
      grid-gap: 0.6em;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 56%;
   } */
}

@media (min-width: 748.8px) {
   .client__topBar__content {
      flex-direction: row;
      justify-content: flex-end;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 48.7%;
      --top-value: 24%;
   } */
}

@media (min-width: 768px) {
   .client__head__span {
      padding: 3.5em;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 50%;
      --top-value: 24%;
   } */
}

@media (min-width: 800px) {
   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 52%;
   } */
}

@media (min-width: 872px) {
   .client__topBar__points__info__wrapper {
      grid-gap: 1em;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 56%;
   } */
}

@media (min-width: 960px) {
   .client__page__wrapper {
      margin-left: 37px;
   }

   .client__topBar__wrapper {
      justify-content: space-between;
   }

   .client__logo__image {
      display: block;
      margin-left: 0.3em;
   }
   /* .client__page__content__wrapper {
      width: 90%;
   } */
   .logo__image {
      display: block;
      margin-left: 0.3em;
   }
   .client__topBar__points__info__wrapper {
      padding-right: 1.2em;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 56.3%;
   } */
}

@media (min-width: 1020px) {
   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 59.1%;
   } */
}

@media (min-width: 1200px) {
   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 65.8%;
   } */
}

@media (min-width: 1328px) {
   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 69.4%;
   } */
}

@media (min-width: 1440px) {
   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 71.7%;
   } */
}

@media (min-width: 1680px) {
   .client__topBar__wrapper {
      font-size: 1rem;
   }

   .client__topBar__wrapper {
      font-size: 1rem;
   }

   .client__topBar__points__info__wrapper__size__controller {
      font-size: 0.8rem;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 74.2%;
      --top-value: 20%;
   } */
}

@media (min-width: 2080px) {
   .client__topBar__wrapper {
      font-size: 1.2rem;
   }

   .client__topBar__wrapper {
      font-size: 1.2rem;
   }

   .client__topBar__points__info__wrapper__size__controller {
      font-size: 0.9rem;
   }

   /* .client__topBar__points__info__wrapper--dynamic {
      --left-value: 77.6%;
      --top-value: 22%;
   } */
}
