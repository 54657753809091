@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&family=Mulish&family=Poppins&family=Roboto:wght@500;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

:root {
   /* adjust 250px*/
   --sidebar-open-value: 210px;
}

.sideBar__admin {
   position: fixed;
   left: 0;
   top: 0;
   display: flex;
   flex-direction: column;
   height: 100vh;
   z-index: 10;
   overflow-x: hidden;
   transition: 0.3s ease-in-out;
   background: #ffffff;
   box-shadow: 0px 0.25em 1.0625em #d2dbff;
   width: 0;
}

.sideBar__admin:hover {
   width: var(--sidebar-open-value);
}

.sideBar__admin:hover .sideBar__admin__logo__image {
   opacity: 1;
}

.sideBar__admin--open {
   width: var(--sidebar-open-value);
}

.clientAdmin__page__content__wrapper {
   margin-left: 0;
   transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
   width: 100%;
}

.sideBar__admin__toggle__hamburger__icon__outside {
   position: absolute;
   left: 0;
   width: 3em;
   height: 3em;
   margin-bottom: 2em;
   align-self: center;
   opacity: 1;
   transition: opacity 0.3s ease-in-out;
   cursor: pointer;
   z-index: 1;
}

.sideBar__admin__toggle__hamburger__icon {
   left: 0;
   width: 3em;
   height: 3em;
   margin-bottom: 2em;
   align-self: center;
   opacity: 1;
   cursor: pointer;
   transition: opacity 0.15s ease-in-out;
}

.sideBar__admin:hover .sideBar__admin__toggle__hamburger__icon {
   opacity: 0;
}

.sideBar__admin__toggle__hamburger__icon--open {
   opacity: 0;
}

.sideBar__admin__wrapper {
   display: flex;
   flex-direction: column;
   align-self: center;
   justify-self: center;
   flex-wrap: nowrap;
   margin: auto 0;
   gap: 2.3em;
   padding: 1em 0;
   overflow-x: hidden;
   width: 100%;
   font-family: "Rubik";
   font-style: normal;
   font-weight: 400;

   /* adjust */
   font-size: 0.8rem;
}

.sideBar__admin__logo__image {
   align-self: center;
   width: 100%;
   height: 4em;
   max-width: 13.5em;
   object-fit: contain;
   margin-bottom: 2em;
   opacity: 1;

   /* adjust speed */
   transition: opacity 0.2s ease-in-out;
}

.sideBar__admin__logo__image--open {
   opacity: 1;
}

.sideBar__admin__logo__image--small {
   width: 3em;
   height: 4em;
}

.sideBar__admin__navigation__item__wrapper {
   display: flex;
   align-items: center;
   align-self: flex-start;
   justify-self: flex-start;
   flex: 0;
   gap: 1.7em;
   justify-content: flex-start;
   padding: 0.5em 1em;
   width: 100%;
   border-left: 4px solid var(--base-white-color);
   cursor: pointer;
   transition: 0.2s ease-in-out;
}

.sideBar__admin__navigation__item__wrapper:hover {
   box-shadow: 5px 4px 10px #87a1fe;
   background-color: rgb(255, 255, 255);
}

.sideBar__admin__navigation__item__wrapper--selected {
   border-left: 4px solid var(--base-accent-color-light);
}

.sideBar__admin__navigation__item__image {
   width: 2em;
   height: 2em;
   object-fit: cover;
}

.sideBar__admin__navigation__item__text {
   font-size: 1.25em;
   line-height: 1.2em;
   flex-shrink: 0;
   color: #555659;
}

.sideBar__admin__navigation__item__text--active {
   font-weight: 500;
   color: var(--base-accent-color-light);
}

@media (min-width: 374px) {
}

@media (min-width: 476px) {
   :root {
      --sidebar-open-value: 220px;
   }

   .sideBar__admin__wrapper {
      font-size: 0.85rem;
   }
}

@media (min-width: 636.8px) {
}

@media (min-width: 636.8px) {
}

@media (min-width: 748.8px) {
}

@media (min-width: 768px) {
}

@media (min-width: 800px) {
   :root {
      --sidebar-open-value: 230px;
   }

   .sideBar__admin__wrapper {
      font-size: 0.9rem;
   }
}

@media (min-width: 872px) {
}

@media (min-width: 960px) {
   :root {
      --sidebar-open-value: 250px;
   }
   .sideBar__admin {
      width: 70px;
   }

   /* .sideBar__admin--open {
      width: var(--sidebar-open-value);
   } */

   .sideBar__admin:hover {
      width: var(--sidebar-open-value);
   }

   .sideBar__admin__toggle__hamburger__icon__outside {
      display: none;
   }

   .clientAdmin__page__content__wrapper {
      margin-left: 40px;
      width: calc(100% - 60px);
      transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
   }

   .clientAdmin__page__wrapper:has(.sideBar__admin:hover)
      .clientAdmin__page__content__wrapper {
      margin-left: calc(var(--sidebar-open-value) - 35px);
      width: calc(100% - var(--sidebar-open-value) + 15px);
   }

   .clientAdmin__page__content__wrapper--sidebar__open {
      margin-left: calc(var(--sidebar-open-value) - 35px);
      width: calc(100% - var(--sidebar-open-value) + 15px);
   }

   .sideBar__admin__logo__image {
      transition: opacity 0.4s ease-in-out;
   }
}

@media (min-width: 1020px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1328px) {
}

@media (min-width: 1440px) {
   .sideBar__admin__wrapper {
      font-size: 1rem;
   }

   :root {
      --sidebar-open-value: 260px;
   }

   .sideBar__admin {
      width: 75px;
   }

   .sideBar__admin--open {
      width: var(--sidebar-open-value);
   }

   .sideBar__admin:hover {
      width: var(--sidebar-open-value);
   }

   .clientAdmin__page__content__wrapper {
      margin-left: 45px;
      width: calc(100% - 65px);
      transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
   }

   .clientAdmin__page__wrapper:has(.sideBar__admin:hover)
      .clientAdmin__page__content__wrapper {
      margin-left: calc(var(--sidebar-open-value) - 40px);
      width: calc(100% - var(--sidebar-open-value) + 10px);
   }

   .clientAdmin__page__content__wrapper--sidebar__open {
      margin-left: calc(var(--sidebar-open-value) - 40px);
      width: calc(100% - var(--sidebar-open-value) + 10px);
   }

   .sideBar__admin__toggle__hamburger__icon__outside {
      display: none;
   }

   .sideBar__admin__logo__image {
      align-self: center;
      transition: opacity 0.4s ease-in-out;
   }
}

@media (min-width: 1680px) {
}

@media (min-width: 2080px) {
}
