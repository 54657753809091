@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&family=Mulish&family=Poppins:wght@400;500&family=Roboto:wght@500;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

.notifications__bell {
  background: none;
  border: none;
}

.notifications__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1.6875em;

  /* adjust */
  font-size: 0.7rem;
}

/* notifications header */

.notifications__header__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;

  /* adjust */
  margin-bottom: 1.5em;
  gap: 0.375em;
}

.notifications__header__text {
  font-size: 1.25em;
  line-height: 1.2em;
  color: var(--base-accent-color-darker);
}

.notifications__header__number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
  width: 1.7625em;
  height: 1.7625em;
  color: var(--base-white-color);
  background: linear-gradient(123.16deg, #222af0 -0.82%, #aabdff 100%);
  border-radius: 40%;
}

/* notifications body */

.notifications__body__wrapper {
  display: flex;
  flex-direction: column;
}

.notifications__body__old__wrapper,
.notifications__body__new__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* notification body header */

.notifications__body__header__wrapper {
  display: flex;
  align-items: center;
  gap: 1em;
}

.notifications__body__header__text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 1.7333em;
  color: var(--base-accent-color-darker);
}

.notifications__body__header__image {
  width: 0.62625em;
  height: 0.5em;
}

/* notification body content */

.notifications__body__content__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1em;
  margin-bottom: 0.5em;
}

.notifications__body__content__inside__wrapper {
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
}

.notifications__body__content__image {
  width: 12vw;
  max-width: 5.6875em;
  max-height: 5.6875em;
}

.notifications__body__content__header {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.2em;
  color: var(--base-accent-color-darker);
}

.notifications__body__content__text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375em;
  line-height: 1.3333em;
  letter-spacing: -0.032em;
  text-transform: capitalize;
  color: var(--base-accent-color-darker);
}

.notifications__body__content__date {
  display: flex;
  flex: 0;
  width: 100%;
  align-items: center;
  background-color: var(--base-accent-color-darker);
  font-size: 0.9375em;
  border-radius: 0.6667em;
  padding: 0.1333em 0.6667em;
  color: var(--base-white-color);
}

@media (min-width: 374px) {
}

@media (min-width: 476px) {
  .notifications__wrapper {
    font-size: 0.8rem;
  }
}

@media (min-width: 636.8px) {
  .notifications__wrapper {
    font-size: 0.85rem;
  }
}

@media (min-width: 636.8px) {
}

@media (min-width: 748.8px) {
}

@media (min-width: 768px) {
}

@media (min-width: 800px) {
}

@media (min-width: 872px) {
}

@media (min-width: 960px) {
}

@media (min-width: 1020px) {
  .notifications__wrapper {
    font-size: 0.9rem;
  }
  .notifications__body__content__wrapper {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1328px) {
}

@media (min-width: 1440px) {
}

@media (min-width: 1680px) {
}

@media (min-width: 2080px) {
  .notifications__wrapper {
    font-size: 1rem;
  }
}
