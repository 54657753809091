.auth-form-page {
   background-size: cover;
   background-repeat: no-repeat;
   border-style: none;
   height: 100vh;
   display: flex;
   position: relative;
   background-color: var(--base-white-color);
   font-family: "Rubik";
   font-style: normal;
   font-weight: 400;
   color: var(--base-accent-color-dark);
}

.auth-form--wrapper.sign-in {
   max-height: 45em;
}

.auth__form__backArrow__wrapper {
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: absolute;
   gap: 0.2em;
   width: 95%;
   font-size: 1.4em;
   font-weight: 400;
   top: 0.6em;
   left: 0.3em;
   z-index: 1;
   color: var(--base-accent-color-light);
}
.auth__form__backArrow__button__wrapper {
   display: flex;
   align-items: center;
   cursor: pointer;
}

.auth__form__dark__color {
   color: var(--base-accent-color-dark);
   font-weight: 600;
}
.auth__form__light__color {
   color: var(--base-accent-color-light);
   font-weight: 400;
   cursor: pointer;
}

.auth__form__light__color:hover {
   text-decoration: underline;
}

.auth__form__text__clicked {
   color: var(--base-accent-color-dark);
   text-decoration: underline;
   font-weight: 600;
}

.auth__form__backArrow {
   width: 1em;
   height: auto;
}

.auth-form {
   align-items: center;
   background-color: #fff;
   border-radius: 0.4em;
   box-shadow: 0 0.25em 2.5em #d2dbff;
   display: grid;
   font-size: 2rem;
   grid-template-columns: 1fr;
   margin: auto;
   max-width: 24em;
   width: max-content;
   /* min-width: 24em; */
   padding: 4em 6em;
   place-content: center;
   place-items: center;
   position: relative;
}

.auth-form.sign-up {
   padding: 3em 6em;
}

.auth-form--logo {
   margin-bottom: 3.668em;
   width: 68%;
}

.auth-form--header.sign-in {
   font-weight: 500;
   font-size: 2.1875em;
   line-height: 0.4571em;
   margin-bottom: 1.2857em;
}

.auth-form--header.sign-up {
   font-weight: 500;
   font-size: 1.5625em;
   line-height: 0.64em;
   margin-bottom: 1.8em;
}

.auth-form--text {
   font-size: 1.5625em;
   line-height: 0.64em;
   margin-bottom: 1.28em;
}

.auth-form--horizontal-line {
   width: 68%;
   margin-bottom: 4.088em;
   border-left: none;
   border-right: none;
   border-bottom: none;
   border-top: 1px solid #c4c4c4;
}

.auth-form-input--wrapper {
   position: relative;
}

.auth-form-input--wrapper label {
   display: inline-block;
   font-size: 1.25em;
   margin-bottom: 0.4em;
   position: absolute;
   padding: 0.15em;
   z-index: 1;
}

.auth-form-input--wrapper label:first-child {
   top: -0.7em;
   left: 0.5em;
}

.auth-form-input--wrapper label:nth-child(3) {
   top: 3.1em;
   left: 0.5em;
}

.auth-form-input--wrapper label:nth-child(5) {
   top: 7em;
   left: 0.5em;
}

.auth-form-input--wrapper label:nth-child(7) {
   top: 10.8em;
   left: 0.5em;
}

.auth-form-input--wrapper label:nth-child(9) {
   top: 14.75em;
   left: 0.5em;
}

.auth-form-input--wrapper label:nth-child(11) {
   top: 18.75em;
   left: 0.5em;
}

.auth-form-input--wrapper label:nth-child(13) {
   top: 22.6em;
   left: 0.5em;
}

.auth-form-input--wrapper label::after {
   content: " ";
   background-color: var(--base-white-color);
   width: 100%;
   height: 1.1em;
   position: absolute;
   left: 0;
   bottom: 0;
   z-index: -1;
}

.auth-form-input--wrapper input,
.auth-form-input--wrapper select {
   width: 100%;
   border: 1px solid rgb(38, 50, 56, 0.16);
   border-radius: 0.2em;
   padding: 0.84em 0;
   outline: none;
   font-size: 1.25em;
   margin-bottom: 0.95em;
   cursor: text;
   text-indent: 3%;
}

.auth-form-input--wrapper input:focus {
   border: 1px solid var(--base-accent-color-light);
}

.auth-form-link--forgot-password {
   width: 100%;
   text-decoration: none;
   margin-bottom: 1.9333em;
   font-size: 0.9375em;
   line-height: 1.0666em;
   text-align: right;
   color: var(--base-accent-color-light);
}

.auth-form--button {
   background: var(--base-accent-color-light);
   width: 102%;
   border-radius: 0.5em;
   border-style: none;
   color: var(--base-white-color);
   font-weight: 500;
   font-size: 1.25em;
   line-height: 0.8em;
   padding: 0.8em 0;
   margin-bottom: 0.6em;
   cursor: pointer;
}

.auth-form--button__darker {
   background: var(--base-accent-color-dark);
}

.auth-form-prompt--sign-up {
   display: inline-block;
   font-family: "Catamaran";
   font-style: normal;
   font-weight: 400;
   font-size: 0.9375em;
   line-height: 1.0666em;
   white-space: nowrap;
   color: #455063;
}

.auth-form-prompt--sign-in {
   display: flex;
   flex-direction: column;
   align-items: center;
   font-size: 0.9375em;
   font-weight: 400;
   line-height: 1.0666em;
   gap: 0.6667em;
   color: var(--base-accent-color-dark);
}

.auth-form-prompt--sign-up #signUp-link,
.auth-form-prompt--sign-in #signIn-link {
   text-decoration: none;
   color: #3eaae4;
}

#signUp-link:hover,
#signIn-link:hover {
   color: var(--base-accent-color-dark);
}

.auth-form--sign-in-logos {
   display: flex;
   justify-content: center;
   width: 100%;
   height: 1.6em;
   gap: 4.096em;
}

.auth-form--sign-in-logos img {
   height: 100%;
   cursor: pointer;
}

/* check box Terms Of Service */

.client__setting__form__checkbox__label {
   display: flex;
   gap: 2.5em;
   font-size: 1.25em;
   line-height: 1.2em;
   text-transform: capitalize;
}

/* Hide the browser's default checkbox */
.client__setting__form__checkbox__input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

/* Create a custom checkbox */
.client__setting__form__checkbox__check {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: -30px;
   height: 100%;
   width: 1.2625em;
   margin-right: 2em;
   background-color: #eee;
}

/* On mouse-over, add a grey background color */
.client__setting__form__checkbox__label:hover
   input
   ~ .client__setting__form__checkbox__check {
   background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.client__setting__form__checkbox__label
   input:checked
   ~ .client__setting__form__checkbox__check {
   background-color: var(--base-accent-color-dark);
}

/* Create the checkmark/indicator (hidden when not checked) */
.client__setting__form__checkbox__check:after {
   content: "";
   position: absolute;
   display: none;
}

/* Show the checkmark when checked */
.client__setting__form__checkbox__label
   input:checked
   ~ .client__setting__form__checkbox__check:after {
   display: block;
}

/* Style the checkmark/indicator */
.client__setting__form__checkbox__label
   .client__setting__form__checkbox__check:after {
   left: 0.3625em;
   top: 0.125em;
   width: 0.3125em;
   height: 0.625em;
   border: solid var(--base-white-color);
   border-width: 0 0.1775em 0.1775em 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
}

.client__setting__form__notifications__wrapper {
   position: relative;
   display: flex;
   flex-direction: column;
   width: max-content;
   margin-bottom: 1em;
}

.client__setting__form__checkbox__link {
   color: var(--base-accent-color-darker);
}

@media (min-width: 374px) {
   .auth-form {
      font-size: 0.6rem;
   }
}

@media (min-width: 476px) {
   .auth-form {
      font-size: 0.7rem;
   }
}

@media (min-width: 636.8px) {
   .auth-form-page {
      background-size: contain;
   }
}

@media (min-width: 636.8px) and (min-height: 800px) {
   .auth-form {
      font-size: 0.8rem;
   }
}

@media (min-width: 748.8px) and (min-height: 800px) {
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 13%;
   }
}

@media (min-width: 768px) and (min-height: 800px) {
}

@media (min-width: 800px) and (min-height: 800px) {
}

@media (min-width: 872px) and (min-height: 800px) {
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 18%;
   }
}

@media (min-width: 960px) and (min-height: 800px) {
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 20%;
   }
}

@media (min-width: 1020px) and (min-height: 800px) {
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 18%;
   }
}

@media (min-width: 1200px) and (min-height: 800px) {
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 14%;
   }
}

@media (min-width: 1328px) and (min-height: 800px) {
   .auth-form.demo {
      font-size: 0.9rem;
   }
}

@media (min-width: 1440px) and (min-height: 800px) {
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 8%;
   }
}

@media (min-width: 1680px) and (min-height: 800px) {
   .auth-form {
      font-size: 0.9rem;
   }
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 3%;
   }
   .auth-form.demo {
      font-size: 1rem;
   }
}

@media (min-width: 2080px) and (min-height: 800px) {
   .auth-form {
      font-size: 1.1rem;
   }
   .auth-form.sign-up,
   .auth-form.sign-in {
      left: 6%;
   }
   .auth-form.demo {
      font-size: 1.1rem;
   }
}
